<template>
  <section id="productos">
    <!-- <div id="loading-bg" v-if="loading">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div> -->

    <b-col cols="14">
      <b-card>
        <!-- Header -->
        <b-card-header class="px-0 py-0">
          <div class="container p-0">
            <div class="row align-items-center">
              <div class="col-md">
                <b-card-title> Productos </b-card-title>
              </div>
              <div class="col-md col-12">
                <div style="display: flex">
                  <div style="display: flex; margin-left: auto">
                    <!-- <div class="mr-1">
                      <b-input-group>
                        <b-form-input
                          placeholder="Buscar un Producto"
                          id="buscar-input"
                          v-model="buscar"
                        />
                        <b-input-group-append>
                          <b-button
                            @click="searchProduct()"
                            variant="info shadow-none"
                          >
                            Buscar
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="mr-1">
                      <b-button
                        variant="info"
                        @click="modalShow = !modalShow"
                        v-b-modal.modal-center
                        class="shadow-none"
                      >
                        <feather-icon icon="FilterIcon" class="mr-50" />
                        <span class="align-middle">Filtrar</span>
                      </b-button>
                    </div> -->
                    <div v-if="$store.state.appConfig.rol == 3">
                      <router-link to="/dashboard/productos/ProductoNuevo">
                        <b-button variant="primary" class="shadow-none "
                          >Crear Producto</b-button
                        >
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-header>
      </b-card>
    </b-col>
    <div v-if="!producto">
      <h1>No hay productos creados</h1>
    </div>
    <b-col cols="14" v-if="producto">
      <b-card
        v-for="product in getproducto2(this.$store.state.appConfig.projecto.id)"
        :key="product"
      >
        <!-- Header -->
        <b-card-header class="px-0">
          <div class="container p-0">
            <div class="row align-items-center">
              <div class="col">
                <div class="row align-items-end">
                  <b-card-title class="ml-1">{{ product.name }}</b-card-title>
                  <!-- <label class="ml-2 mb-0 p-0"
                    >ID
                    <span class="font-weight-bolder">{{
                        product.id
                    }}</span></label
                  > -->
                  <!-- <b-button variant="none" @click="showAlert">
                    <feather-icon icon="CopyIcon" />
                  </b-button> -->
                  <!-- <b-alert
                    v-height-fade.appear
                    :show="dismissCountDown"
                    dismissible
                    class="mb-0"
                    variant="info"
                    id="id-popup"
                    @dismissed="dismissCountDown = 0"
                    @dismiss-count-down="countDownChanged"
                  >
                    <div class="alert-body">
                      <span class="bold">ID copiado en portapapeles </span>
                    </div>
                  </b-alert> -->
                </div>
              </div>
              <div class="col-1">
                <div style="display: flex">
                  <div style="display: flex; margin-left: auto">
                    <div
                      v-if="$store.state.appConfig.rol == 3"
                      class="dropdown"
                    >
                      <b-dropdown
                        variant="none"
                        no-caret
                        toggle-class="p-0"
                        menu-class="clase-menu"
                        right
                        id="menu-options"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          @click="goToProductEdit()"
                          :active-class="'hover-dropdown'"
                        >
                          <feather-icon icon="Edit2Icon" />
                          <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="
                            modalEliminarProductoShow = !modalEliminarProductoShow
                          "
                          v-b-modal.modal-eliminar-producto
                        >
                          <feather-icon icon="Trash2Icon" />
                          <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-header>

        <b-card class="px-0">
          <div class="mb-2">
            <label class="label-titles">Pasarelas</label>
            <b-row>
              <div class="mb-md-0 mb-1" v-for="pasarela in product.credential" :key="pasarela.id">
                <!-- <b-button
                  variant="none"
                  @click="showInputsCredential(pasarela)"
                  class="p-0"
                > -->
                <span
                  :class="
                    visiblePasarela == pasarela.id_gateway
                      ? 'label-pasa-selected'
                      : 'label-pasa'
                  "
                >
                  {{
                    pasarela.id_gateway === 1
                      ? 'Kushki'
                      : pasarela.id_gateway === 2
                      ? 'Stripe'
                      : pasarela.id_gateway === 3
                      ? 'Suscripciones Móviles'
                      : null
                  }}
                </span>
                <!-- </b-button> -->
                <!-- <b-button
                  variant="none"
                  @click="handleShowPasarela(pasarela.id)"
                > -->
                <b-badge
                  class="font-weight-normal ml-1 mr-4"
                  pill
                  :variant="
                    pasarela.showCredentials === 1
                      ? 'light-success'
                      : 'light-dark'
                  "
                >
                  {{ pasarela.showCredentials === 1 ? 'Activa' : 'Inactiva' }}
                </b-badge>
                <!-- </b-button> -->
              </div>
            </b-row>
            <div class="line" />
          </div>

          <!-- -->
          <div class="mb-2">
            <label class="label-titles">Medios de Pago</label>
            <div class="row">
              <b-col cols="md-3 6">
                <div class="row">
                  <div class="mb-1">
                    <feather-icon icon="CreditCardIcon" class="mr-1" />
                    <label class="label-pasarelas">Tarjeta</label>
                    <b-badge
                      class="font-weight-normal"
                      pill
                      :variant="
                        product.card_enable === 1
                          ? 'light-success'
                          : 'light-dark'
                      "
                    >
                      {{ product.card_enable === 1 ? 'Activa' : 'Inactiva' }}
                    </b-badge>
                  </div>
                </div>
              </b-col>
              <b-col cols="md-3 6">
                <b-row>
                  <div class="mb-1">
                    <feather-icon icon="Minimize2Icon" class="mr-1" />

                    <label class="label-pasarelas">Transferencia</label>
                    <b-badge
                      class="font-weight-normal"
                      pill
                      :variant="
                        product.trasfer_enable === 1
                          ? 'light-success'
                          : 'light-dark'
                      "
                    >
                      {{ product.trasfer_enable === 1 ? 'Activa' : 'Inactiva' }}
                    </b-badge>
                  </div>
                </b-row>
              </b-col>
              <b-col cols="md-3 6">
                <b-row>
                  <div class="mb-1">
                    <feather-icon icon="DollarSignIcon" class="mr-1" />
                    <label class="label-pasarelas">Efectivo</label>
                    <b-badge
                      class="font-weight-normal"
                      pill
                      :variant="
                        product.cash_enable === 1
                          ? 'light-success'
                          : 'light-dark'
                      "
                    >
                      {{ product.cash_enable === 1 ? 'Activa' : 'Inactiva' }}
                    </b-badge>
                  </div>
                </b-row>
              </b-col>
              <!-- <b-col cols="md-3 6">
                <b-row>
                  <div class="mb-1">
                    <feather-icon icon="SmartphoneIcon" class="mr-1" />
                    <label class="label-pasarelas">Entel</label>
                    <b-badge
                      class="font-weight-normal"
                      pill
                      :variant="
                        product.entel_enable === 1
                          ? 'light-success'
                          : 'light-dark'
                      "
                    >
                      {{ product.entel_enable === 1 ? 'Activa' : 'Inactiva' }}
                    </b-badge>
                  </div>
                </b-row>
              </b-col>
              <b-col cols="md-3 6">
                <b-row>
                  <div class="mb-1">
                    <feather-icon icon="SmartphoneIcon" class="mr-1" />
                    <label class="label-pasarelas">Movistar</label>
                    <b-badge
                      class="font-weight-normal"
                      pill
                      :variant="
                        product.movistar_enable === 1
                          ? 'light-success'
                          : 'light-dark'
                      "
                    >
                      {{
                        product.movistar_enable === 1 ? 'Activa' : 'Inactiva'
                      }}
                    </b-badge>
                  </div>
                </b-row>
              </b-col> -->
            </div>
            <div class="line" />
          </div>
          <!-- -->
          <div class="mb-2">
            <label class="label-titles">Servicios</label>
            <b-row>
              <b-col class="my-md-0 mb-1" cols="md-3">
                <b-row>
                  <feather-icon icon="CalendarIcon" class="mr-1" />
                  <label class="label-pasarelas">Suscripciones</label>
                  <b-badge
                    class="font-weight-normal"
                    pill
                    :variant="
                      product.suscription_enable === 1
                        ? 'light-success'
                        : 'light-dark'
                    "
                  >
                    {{
                      product.suscription_enable === 1 ? 'Activa' : 'Inactiva'
                    }}
                  </b-badge>
                </b-row>
              </b-col>
              <b-col class="my-md-0 mb-1" cols="md-3">
                <b-row>
                  <feather-icon icon="Link2Icon" class="mr-1" />
                  <label class="label-pasarelas">Links de Pago</label>
                  <b-badge
                    class="font-weight-normal"
                    pill
                    :variant="
                      product.link_enable === 1 ? 'light-success' : 'light-dark'
                    "
                  >
                    {{ product.link_enable === 1 ? 'Activa' : 'Inactiva' }}
                  </b-badge>
                </b-row>
              </b-col>
              <b-col class="my-md-0 mb-1" cols="md-3">
                <b-row>
                  <feather-icon icon="GiftIcon" class="mr-1" />
                  <label class="label-pasarelas">Códigos de Descuento</label>
                  <b-badge
                    class="font-weight-normal"
                    pill
                    :variant="
                      product.discount_enable === 1
                        ? 'light-success'
                        : 'light-dark'
                    "
                  >
                    {{ product.discount_enable === 1 ? 'Activa' : 'Inactiva' }}
                  </b-badge>
                </b-row>
              </b-col>
            </b-row>
            <div class="line" />
          </div>
          <div>
            <b-row>
              <b-col cols="12">
                <div>
                  <label class="label-titles">Moneda</label>
                  <b-row>
                    
                    <b-badge
                      class="pill-style font-weight-normal"
                      pill
                      variant="info"
                      >
                        
                        {{product.monedaFilter.code}} - {{product.monedaFilter.name}}
                      </b-badge
                    >
                  </b-row>
                  
                  <label class="label-titles">Moneda Secundarias</label>                  
                  <b-row>
                    <b-badge
                      v-if="!product.equivalent"
                      class="pill-style font-weight-normal"
                      pill
                      variant="danger"
                      >
                      No Tiene                    
                      </b-badge
                    >
                    <b-badge
                      v-else
                      v-for="currency in product.currency"
                      :key="currency.id_currency_ref"
                      class="pill-style font-weight-normal"
                      pill
                      variant="info"
                      >
                      {{product.equivalent.code}} - {{product.equivalent.name}}                    
                      </b-badge
                    >
                  </b-row>
                  
                  <label class="label-titles">Terminos y Condiciones</label>
                  <p>
                    {{ product.Terms_and_Conditions }}
                  </p>
                  <label class="label-titles">ApiKey</label>
                  <p>
                    {{ product.apikey }}
                  </p>
                  <b-button v-if="$store.state.appConfig.rol == 3" variant="primary" class="shadow-none" @click="refreshApiKey($store.state.appConfig.projecto.id)"
                          >Refrescar Api Key</b-button
                        >
                  

                </div>
              </b-col>
              <!-- <b-col>
                <div>
                  <label class="label-titles">País</label>
                  <b-row>
                    <b-badge
                      v-for="country in product.country"
                      :key="country.id"
                      class="pill-style font-weight-normal"
                      pill
                      variant="info"
                      >{{ country }}</b-badge
                    >
                  </b-row>
                </div>
              </b-col> -->
            </b-row>
            <div class="line" />
          </div>
        </b-card>
      </b-card>
    </b-col>

    <!-- Modal -->
    <!-- <b-modal
      id="modal-center"
      title="Filtrar por"
      v-model="modalShow"
      centered
      size="sm"
      @ok="validateDates"
      hide-footer
      ref="modal-center"
    >
      <b-form>
        <b-form-group
          label="Pasarela"
          label-for="select-pasarela"
          label-class="font-weight-bolder label-size"
          class="line-modal"
        >
          <v-select
            id="select-pasarela"
            v-model="selectedPasarela"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="['Kushki', 'Stripe', 'PEP']"
            title="Pasarela"
          >
            <span slot="no-options">No hay opciones disponibles</span>
          </v-select>
        </b-form-group>

        <b-form-group
          label="Fecha de Creación"
          label-for="select-fecha"
          label-class="font-weight-bolder label-size"
        >
          <b-row>
            <b-col>
              <b-form-datepicker
                id="desde-datepicker"
                v-model="valueDesde"
                class="btn-outline-info btn-filtro"
                selected-variant="info"
                button-variant="btn-info"
                label-no-date-selected="Desde"
                nav-button-variant="info"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              />
            </b-col>
            <b-col>
              <b-form-datepicker
                id="hasta-datepicker"
                v-model="valueHasta"
                class="btn-outline-info btn-filtro"
                selected-variant="info"
                button-variant="btn-info"
                label-no-date-selected="Hasta"
                nav-button-variant="info"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              />
            </b-col>
          </b-row>
          <b-form-invalid-feedback v-if="errorDate">
            La fecha inicial debe ser menor a la fecha final.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row class="mt-3 margen">
          <b-col>
            <b-button
              variant="primary"
              class="modal-button-size shadow-none"
              @click="validateDates"
            >
              Aplicar filtro
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal> -->

    <!-- Modal Eliminar Producto-->
    <b-modal
      id="modal-eliminar-producto"
      body-bg-variant="light-warning"
      v-model="modalEliminarProductoShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-eliminar-producto"
    >
      <label id="label-confirmacion"
        >¿Seguro que quieres eliminar este producto?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideEliminarModal"
          >
            Cancelar
          </b-button>
          <b-button
            variant="outline-warning"
            class="modal-button-size shadow-none"
            id="agregar-button-modal"
            @click="deleteProduct"
          >
            Eliminar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BTable,
  BAvatar,
  BImg,
  BButton,
  BBadge,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BModal,
  BForm,
  BFormGroup,
  BAlert,
} from 'bootstrap-vue';

import Button from '@/views/components/button/Button.vue';
import { heightFade } from '@core/directives/animations';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axios from 'axios';
import mapState from 'vuex';
import { backendUrl } from '@/config';
import TreeSearchTreeVue from '@/views/extensions/tree/TreeSearchTree.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BButton,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    Button,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BModal,
    BForm,
    BFormGroup,
    vSelect,
    BAlert,
    ToastificationContent,
  },
  created(){
      this.$store.commit('appConfig/changeLoading', true);

  },
  mounted() {
          this.$store.commit('appConfig/changeLoading', false);

    this.getProducts();
  },
  methods: {
    async refreshApiKey(id){
      this.$store.commit('appConfig/changeLoading', true);

      const token = localStorage.getItem('token')
      var data = JSON.stringify({
        id,
      });

      var config = {
        method: 'put',
        url: `${backendUrl}/productos/refresh`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      try {       
        await axios(config)
          const productosdb = await axios({
                method: 'get',
                url: `${backendUrl}/productos/`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
          });
            if(productosdb.data.project.length){
              this.$store.commit('appConfig/saveProyectosTotales', productosdb.data.project);
              this.getProducts()
              this.$store.commit('appConfig/changeLoading', false);
              this.showSuccessRefresh('success')
            } else{
              this.showErrorRefresh('danger')
              this.$store.commit('appConfig/changeLoading', false);
            }
        } catch (error) {
          this.$store.commit('appConfig/saveProyectosTotales', productosdb.data.project);
          return error
        }

    },
    async handleShowPasarela(id) {
      const token = localStorage.getItem('token');

      var data = JSON.stringify({
        id: id,
      });

      var config = {
        method: 'post',
        url: `${backendUrl}/productos/editCredentials/`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      try {
        const resp = await axios(config);
      } catch (error) {
      }
    },

    showInputsCredential(pasarela) {
      this.visibleCredentialsInputs = true;
      this.id_gateway = pasarela.id;
      if (pasarela.id_gateway === 1) {
        this.visiblePasarela = 'Kushki';
      } else {
        this.visiblePasarela = 'Stripe';
      }
    },
    closeInputsCredential() {
      this.visibleCredentialsInputs = false;
      this.id_gateway = null;
      this.visiblePasarela = '';
      this.clavePrivada = '';
      this.clavePublica = '';
    },
    async deleteProduct() {
      this.$store.commit('appConfig/changeLoading', true);

      try {
        const token = localStorage.getItem('token');

        var data = JSON.stringify({
          id: this.$store.state.appConfig.projecto.id,
        });

        var config = {
          method: 'post',
          url: `${backendUrl}/productos/rm/`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: data,
        };

        await axios(config);

        let proyectosStore = this.$store.state.appConfig.proyectos;

        const proyectoFiltrado = proyectosStore.filter(
          (e) => e.id !== this.$store.state.appConfig.projecto.id
        );
        this.$store.commit('appConfig/changeLoading', false);

        this.$store.commit('appConfig/changeProyectosStore', proyectoFiltrado);

        if (proyectoFiltrado.length !== 0) {
          this.$store.commit('appConfig/changeStore', {
            id: proyectoFiltrado[0].id,
            name: proyectoFiltrado[0].name,
            id_currency: proyectoFiltrado[0].id_currency,
            Terms_and_Conditions: proyectoFiltrado[0].Terms_and_Conditions,
          });
        }

        this.hideEliminarModal();
        this.showEliminarProductSuccess('success');
      } catch (err) {
        this.$store.commit('appConfig/changeLoading', false);
        this.showEliminarProductError('danger');

      }
    },
    /* async getUtils() {
      const token = localStorage.getItem('token');

      var config = {
        method: 'get',
        url: `${backendUrl}/operaciones/util`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const resUtils = await axios(config);

    }, */
    async getProducts() {
      this.$store.commit('appConfig/changeLoading', true);

      const token = localStorage.getItem('token');

      const users = await axios({
            method: 'get',
            url: `${backendUrl}/usuarios/`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

      const user = users.data.user;

      var config = {
        method: 'get',
        url: `${backendUrl}/productos/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const resProduct = await axios(config);

      let { project, /* country, */ credential, currency } = resProduct.data;
      let productosArray = [];

      for (let i = 0; i < project.length; i++) {
     
        const dataCredential = await credential.filter(
          (e) => e.id_project === project[i].id
        );
        const dataCurrency = await currency.filter(
          (e) => e.id_project === project[i].id
        );

        project[i].credential = dataCredential;
        project[i].currency = dataCurrency;

        productosArray.push(project[i]);
      }
      this.$store.commit('appConfig/changeLoading', false);

      this.producto = productosArray;
    },
    goToProductEdit() {
      this.$router.push({
        name: 'producto-editar',
        params: { productSelected: this.productSelected },
      });
    },
    getproducto2(productId) {
      try {

      let Projectos = [];

      for (let k = 0; k < this.producto.length; k++) {

          this.$store.commit('appConfig/changeLoading', true);

          if (this.producto[k].id === productId) {
            Projectos.push(this.producto[k]);
            console.log('esto es')
          }  
          this.$store.commit('appConfig/changeLoading', false);

      }
      
      if (Projectos.length > 0) {
        Projectos[0].monedaFilter;
        Projectos[0].equivalent;

      this.$store.commit('appConfig/changeLoading', false);
      

      this.productSelected = Projectos;
      

      Projectos[0].monedaFilter = this.$store.state.appConfig.currency.find((x)=> x.id == Projectos[0].id_currency)
      console.log('PROJECTOS:::', Projectos[0].currency)

      if(Projectos[0].currency.length){
       
        Projectos[0].equivalent = this.$store.state.appConfig.currency.find((m)=> m.id == Projectos[0].currency[0].id_currency_ref)

      }
   
        this.$store.commit('appConfig/changeLoading', false);

        return Projectos;
      }
    } catch (error) {
      this.$store.commit('appConfig/changeLoading', false);

      return error
    }

      
    },
    hideEliminarModal() {
      this.$refs['modal-eliminar-producto'].hide();
    },
    ver(producto) {
    },
    
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showEliminarProductError(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Hubo un error al eliminar el producto',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showEliminarProductSuccess(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Producto Eliminado!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showErrorRefresh(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Hubo un error al actualizar el Api Key',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },

    showSuccessRefresh(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'El Api Key de su producto ha sido actualizada',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showGenerarReporteErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Crear el Reporte',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showProductNotFoundToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Producto no encontrado',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    /* validateDates() {
      if (new Date(this.valueDesde) > new Date(this.valueHasta)) {
        this.errorDate = true;
      } else {
        this.errorDate = false;
        this.hideFiltrarModal();
      }
    }, */
    searchProduct() {
      if (this.buscar != 'Divino Tarot') {
        this.showProductNotFoundToast('danger');
      }
    },
  },
  directives: {
    'height-fade': heightFade,
    Ripple,
  },

  data() {
    return {
      monedaFilter: [],
      equivalentCurrency:[],
      id_gateway: null,
      producto: [],
      selectedPasarela: '',
      modalEliminarProductoShow: false,
      modalShow: false,
      // projectData: [],
      // dataPaises: [],
      // optionPaises: [],
      dismissSecs: 2,
      dismissCountDown: 0,
      valueDesde: '',
      valueHasta: '',
      errorDate: false,
      buscar: '',
      clavePublica: '',
      clavePrivada: '',
      visibleCredentialsInputs: false,
      visiblePasarela: '',
      productSelected: {},
      productCurrency: null,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '~@core/scss/base/pages/app-todo.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

#id-popup {
  width: 65%;
}

.pill-style {
  margin-right: 4px;
  margin-bottom: 2px;
  margin-top: 4px;
}

.data-items-size {
  font-size: 12px;
}

::v-deep .table th {
  text-align: center !important;
}

.action-button {
  border: none;
  background: transparent;
  width: 18px;
}

input::placeholder {
  color: #b9b9c3;
}

#more-options-button {
  border: none;
  background: transparent;
}

#icon-more {
  size: 16px;
}

.label-titles {
  color: $primary !important;
  font-size: 14px;
  margin-left: -20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hover-dropdown {
  background: $secondary !important;
  color: white !important;
}

.label-pasarelas {
  font-size: 12px;
  margin-right: 16px;
  margin-left: 0px;
}

.label-pasa {
  font-size: 12px;

  margin-left: 0px;
  font-weight: bold;
  color: $dark;
}

.label-pasa-selected {
  font-size: 12px;
  margin-left: 0px;
  font-weight: bold;
  color: $primary;
}

.line {
  border-bottom: 1px solid #e9ecef;
  margin-top: 16px;
  margin-left: -16px;
  margin-right: -16px;
}

.line-modal {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 24px;
}

.modal-button-size {
  width: 166px;
}

#agregar-button-modal {
  margin-left: 14px;
}

#label-confirmacion {
  margin-left: 10%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

#buscar-input:focus {
  border-color: $info;
}

.clase-menu {
  background: $secondary;
}

.margen {
  margin-left: 50%;
}

/* #menu-options ul li:hover{
  background: $secondary  !important;
  color: white !important;
} */
</style>
